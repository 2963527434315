import React from 'react';
import AssignmentGroup from './assignment_group'
import AssignmentGroupCollapsed from './assignment_group_collapsed'
import InfoTip from "../shared/info_tip";
import { SearchBar } from '../search'
import * as ajaxClients from '../../ajax_clients';
import allSettled from 'promise.allsettled';
import { bindMethods, escapeRegex } from "../../shared/functions";

const formatAppGroups = (appGroups) => {
    return appGroups.map(appGroup => {
        const apps             = appGroup.apps.map(app => {
            return { ...app, type: 'app', appGroup: appGroup }
        })
        const latestVersion    = apps[0]
        appGroup.apps          = apps
        appGroup.latestVersion = latestVersion
        return appGroup
    })
}
export default class AssignmentGroups extends React.Component {

    static matchingItems(ids, items) {
        if (items.length === 0) {
            return []
        }

        return ids.reduce((array, id) => {
            const matchingItem = items.find((item) => {
                return item.id === id
            });
            if (matchingItem) {
                array.push(matchingItem)
            }
            return array;
        }, []);
    }

    static decorateAssignmentGroup(assignmentGroup, state) {
        let matchingApps  = AssignmentGroups.matchingItems(assignmentGroup.appIds, state.catalog.apps);
        const decorations = {
            media:     AssignmentGroups.matchingItems(assignmentGroup.mediaIds, state.catalog.media),
            apps:      matchingApps,
            devices:   AssignmentGroups.matchingItems(assignmentGroup.deviceIds, state.devices),
            groups:    AssignmentGroups.matchingItems(assignmentGroup.groupIds, state.groups),
            profiles: AssignmentGroups.matchingItems(assignmentGroup.profileIds, state.catalog.profiles),
            appGroups: matchingApps.map(app => app.appGroup)
        };
        return Object.assign({}, assignmentGroup, decorations);
    }

    static decorateGroup(group, state) {
        const decorations = {
            devices: AssignmentGroups.matchingItems(group.deviceIds, state.devices)
        };
        return Object.assign({}, group, decorations);
    };

    constructor(props) {
        super(props);
        this.state = {
            assignmentGroups:          [],
            assignmentGroupsCollapsed: {},
            assignmentGroupsHidden:    {},
            catalog:                   {
                apps:      [],
                appGroups: [],
                media:     [],
                profiles:  []
            },
            devices:                   [],
            groups:                    [],
            allDataLoaded:             false
        };
        bindMethods(this)
    }

    componentDidMount() {
        const redecorate = () => {
            this.setState((state) => {
                return {
                    assignmentGroups: state.assignmentGroups.map((assignmentGroup) => AssignmentGroups.decorateAssignmentGroup(assignmentGroup, state)),
                    groups:           state.groups.map(group => AssignmentGroups.decorateGroup(group, state))
                }
            });
        };

        const readAssignmentGroups = ajaxClients.assignmentGroups.read({ per: 'all' }).then(({ data }) => {
            const maximumUncollapsedAssignmentGroups = 4;
            const collapseOnLength                   = data.length > maximumUncollapsedAssignmentGroups ? this.collapseAll : null;
            this.setState((state) => {
                return {
                    assignmentGroups: data.map((assignmentGroup) => AssignmentGroups.decorateAssignmentGroup(assignmentGroup, state))
                }
            }, collapseOnLength);

        });

        const readCatalog = allSettled([
            ajaxClients.appGroups.read({ per: 'all', include_latest: true }),
            ajaxClients.media.read({ per: 'all' }),
            ajaxClients.profiles.read({ per: 'all' }),
        ]).then(response => {
            let [{ value: { data: appGroups } }, { value: { data: media } }, { value: { data: profiles } }] = response

            appGroups  = formatAppGroups(appGroups)
            const apps = appGroups.flatMap(appGroup => appGroup.apps);

            this.setState({
                catalog: { appGroups, media, apps, profiles }
            }, redecorate);

            MDM.fn.preloadImages(apps.map(app => app.iconUrl));
        });

        const readDevices = ajaxClients.devices.read({
            compact:         true,
            enrollmentState: 'all'
        }).then(({ data: devices }) => {
            this.setState({ devices }, redecorate);
        })

        const readGroups = ajaxClients.groups.read({ per: 'all' }).then(({ data }) => {
            this.setState((state) => {
                const groups = data.map((group) => {
                    return AssignmentGroups.decorateGroup(group, state);
                });
                return { groups }
            }, redecorate);
        });

        $.when(readAssignmentGroups, readCatalog, readDevices, readGroups).then(() => {
            this.setState({ allDataLoaded: true }, redecorate);
        });
    }

    updateAssignmentGroup(newAssignmentGroup) {
        this.setState((state) => {
            newAssignmentGroup = AssignmentGroups.decorateAssignmentGroup(newAssignmentGroup, state);
            return {
                assignmentGroups: this.state.assignmentGroups.map((assignmentGroup) => {
                    return assignmentGroup.id === newAssignmentGroup.id ? newAssignmentGroup : assignmentGroup
                })
            }
        });
    }

    destroyAssignmentGroup(assignmentGroupToDestroy) {
        this.setState({
            assignmentGroups: this.state.assignmentGroups.filter((assignmentGroup) => {
                return assignmentGroup.id !== assignmentGroupToDestroy.id
            })
        })
    }

    onSearchChange(search, searchString) {
        if (search === null) {
            this.setState({ assignmentGroupsHidden: {} });
            return;
        }

        const normalizeApostrophe = (string) => string.replace(/[’']/g, "'");
        search                    = new RegExp(escapeRegex(normalizeApostrophe(searchString)), "i")

        const matchingDevices   = this.state.devices.filter(
            device => search.test(normalizeApostrophe(Object.values(device).join(' ')))
        );
        const matchingDeviceIds = matchingDevices.map(device => device.id);

        const matchingDeviceInDeviceGroup = (deviceGroup) => {
            return deviceGroup.devices.some(device => matchingDeviceIds.includes(device.id));
        };

        const matchingDeviceGroupIds = this.state.groups.filter(
            deviceGroup => search.test(normalizeApostrophe(deviceGroup.name)) || matchingDeviceInDeviceGroup(deviceGroup)
        ).map(deviceGroup => deviceGroup.id);

        const matchByName = (collection) => {
            return collection.filter(item => search.test(normalizeApostrophe(item.name))).map(item => item.id);
        };

        const hasMatchById = (assignmentGroupSubCollection, idCollection) => {
            const ids = assignmentGroupSubCollection.map(item => item.id);
            return idCollection.some(id => ids.includes(id))
        };

        const assignmentGroupsHidden = {};
        this.state.assignmentGroups.forEach((assignmentGroup) => {
            if (search.test(assignmentGroup.name)) {
                assignmentGroupsHidden[assignmentGroup.id] = false;
                return;
            }
            assignmentGroupsHidden[assignmentGroup.id] = !(
                hasMatchById(assignmentGroup.groups, matchingDeviceGroupIds) ||
                hasMatchById(assignmentGroup.devices, matchingDeviceIds) ||
                hasMatchById(assignmentGroup.apps, matchByName(this.state.catalog.apps)) ||
                hasMatchById(assignmentGroup.media, matchByName(this.state.catalog.media))
            );
        });
        this.setState({ assignmentGroupsHidden });
    }

    onCollapse(assignmentGroup) {
        this.setState({
            assignmentGroupsCollapsed: Object.assign({}, this.state.assignmentGroupsCollapsed, {
                [assignmentGroup.id]: true
            })
        })
    }

    onExpand(assignmentGroup) {
        this.setState({
            assignmentGroupsCollapsed: Object.assign({}, this.state.assignmentGroupsCollapsed, {
                [assignmentGroup.id]: false
            })
        })
    }

    collapseAll(e) {
        if (e) {
            e.preventDefault();
            e.target.blur();
        }
        const assignmentGroupsCollapsed = {};
        this.state.assignmentGroups.forEach((assignmentGroup) => {
            assignmentGroupsCollapsed[assignmentGroup.id] = true
        });
        this.setState({ assignmentGroupsCollapsed });
    }

    expandAll(e) {
        if (e) {
            e.preventDefault();
            e.target.blur();
        }
        this.setState({
            assignmentGroupsCollapsed: {}
        });
    }

    canCreate() {
        return this.state.assignmentGroups.some(assignmentGroup => {
            return assignmentGroup && assignmentGroup.permissions && assignmentGroup.permissions.create;
        })
    }

    renderAssignmentGroups() {
        return this.state.assignmentGroups.map(assignmentGroup => {
            if (this.state.assignmentGroupsHidden[assignmentGroup.id] === true) {
                return null;
            } else if (this.state.assignmentGroupsCollapsed[assignmentGroup.id] === true) {
                return <AssignmentGroupCollapsed
                    key={ assignmentGroup.id }
                    assignmentGroup={ assignmentGroup }
                    onExpand={ this.onExpand }
                    allDataLoaded={ this.state.allDataLoaded }
                />
            } else {
                return <AssignmentGroup key={ assignmentGroup.id }
                                        assignmentGroup={ assignmentGroup }
                                        catalog={ this.state.catalog }
                                        devices={ this.state.devices }
                                        groups={ this.state.groups }
                                        updateAssignmentGroup={ this.updateAssignmentGroup }
                                        destroyAssignmentGroup={ this.destroyAssignmentGroup }
                                        onCollapse={ this.onCollapse }
                                        allDataLoaded={ this.state.allDataLoaded }
                />
            }
        });
    }

    render() {
        let createAssignmentButton;
        if (this.canCreate()) {
            createAssignmentButton =
                <a href={ AdminRoutes.newAdminAssignmentGroupPath({format: null}) } className="btn btn-primary">
                    Create Assignment Group
                </a>
        }

        return (
            <div>
                <h1>
                    <span className="glyphicon glyphicon-text-background apps-icon"/>
                    Assignments
                    <small>
                        <InfoTip
                            message="Assignment groups associate apps and media with devices and device groups. Assignment groups allow you to install associated apps and media manually or automatically when devices enroll or are moved to a new device group."
                            position="bottom"
                        />
                    </small>
                </h1>
                <div className="top-buttons">
                    <a href="#" onClick={ this.collapseAll } className="collapse-all btn btn-default">
                        Collapse All
                    </a>

                    <a href="#" onClick={ this.expandAll } className="expand-all btn btn-default">
                        Expand All
                    </a>

                    { createAssignmentButton }
                </div>
                <div className="search-controls">
                    <SearchBar
                        onSearchChange={ this.onSearchChange }
                        placeholderText="Filter assignment groups by device, app or media."
                    />
                </div>
                { this.renderAssignmentGroups() }
            </div>
        )
    }
}
