import React from 'react';
import { groups as ajaxClient } from '../../ajax_clients';
import pagedTableLayout from '../paged_table/layout';
import { truncate, bindMethods } from '../../shared/functions';
import { SearchBar } from "../search";

const getAjaxClient = () => ajaxClient;

export const renderFilterSelect = (profile, selected, onChooseFilter) => {
    let options = []
    options.push(<option key="remove-filer" value="">All Groups</option>)
    options.push(<option key="profile-filter" value={ profile.id }>{ `Profile: ${truncate(profile.name, 35)}` }</option>)

    return (
        <select value={ selected } onChange={ onChooseFilter } className="form-control filter">
            { options }
        </select>
    );
}

class GroupsPagedTable extends React.Component {

    constructor(props) {
        super(props);
        bindMethods(this)
    }

    pagedTableProps() {
        return {
            headers:    [
                { displayName: "Name", key: "name" },
                { displayName: "Assigned Devices", key: "assignedDevices" },
            ],
            renderRow:  (row) => {
                const assignedDevices = this.renderAssignedDevices(row);
                const name = (
                    <a href={ AdminRoutes.adminGroupPath(row.id, {format: null}) }>
                        { truncate(row.name, 50) }
                    </a>
                );
                return Object.assign({}, row, { name, assignedDevices });
            },
            showCheck: false,
        };
    }

    renderAssignedDevices(row) {
        return <a href={ AdminRoutes.adminDevicesPath({ q: { group: row.id }, format: null }) }>
            { row.assignedDeviceCount }
        </a>;
    }

    renderActions() {
        if (this.props.policy.create) {
            return (
                <a className="btn btn-primary"
                   href={ AdminRoutes.newAdminGroupPath({ format: null }) }>Create Group</a>
            )
        }
    }

    onSearchChange(searchRegex, search) {
        if (search !== this.props.query.search) {
            this.props.setQuery({
                search,
                page: 1
            })
        }
    }

    onChooseFilter(e) {
        const filterId = e.target.value
        this.props.setQuery({
            page:  1,
            profile_id: filterId
        });
    }

    renderSearchBar() {
        return <SearchBar
            searchOnEnter={ true }
            timeout={ 600 }
            searching={ this.props.searching }
            key={ this.props.query.group_id }
            initialValue={ this.props.query.search }
            placeholderText='Search by group name'
            onSearchChange={ this.onSearchChange }>
        </SearchBar>;
    }

    renderFilterSelect() {
        if (this.props.profile) {
            return renderFilterSelect(this.props.profile, this.props.query.profile_id, this.onChooseFilter)
        }
    }

    render() {
        return (
            <div>
                <div className="special-container">
                    <h1><span className="glyphicon glyphicon-phone devices-icon"/>Groups</h1>
                    <div className="top-buttons">
                        { this.renderActions() }
                    </div>
                </div>
                <div className="search-controls" onSubmit={ (e) => e.preventDefault() }>
                    { this.renderFilterSelect() }
                    { this.renderSearchBar() }
                </div>
                <div id="groups-table" className="well">
                    { this.props.dataNotFound ? null : this.props.renderPagedTable(this.pagedTableProps()) }
                </div>
            </div>
        )
    }
}

export default pagedTableLayout(GroupsPagedTable, getAjaxClient);