import React, { useEffect, useCallback, useMemo, useState } from "react"
import {default as ReactJoyride} from "react-joyride"
import { visitURL } from "../../shared/query"
import { v2Route } from "../../shared/functions"
import { parseQuery } from "../../shared/query"

const StepDefaults = {
  placement: "center",
  locale: { close: "Done", last: "Done", nextLabelWithProgress: 'Next ({step}/{steps})' },
  styles: { tooltipContainer: { textAlign: "left" } },
  target: "body",
}

const FirstCatalogStepNumber = 6
const LastCatalogStepNumber = 7

const PlaceholderStep = { ...StepDefaults, content: "" }

const Steps = [
  // step 1
  PlaceholderStep, // "Welcome" rendered in NextJS "Devices" page
  // step 2
  PlaceholderStep, // "Device Inventory" rendered in NextJS "Devices" page
  // step 3
  PlaceholderStep, // "Enrolling Devices" rendered in NextJS "Devices" page
  // step 4
  PlaceholderStep, // "Grouping Devices" rendered in NextJS "Devices" page
  // step 5
  PlaceholderStep, // "Creating Configurations" rendered in NextJS "Devices" page
  {
    // step 6
    ...StepDefaults,
    content: (
      <>
        <p>
          <strong>Adding Apps & Media</strong>
        </p>
        <p>The Catalog is where you add apps and media that you want to deploy to devices. You can add App Store apps, custom/enterprise apps, and for macOS, select from our list of Shared Apps. You can also upload media such as PDFs and iBooks.</p>
        <p>Apps & Books from Apple Business Manager or Apple School Manager will appear here automatically once you have connected a VPP token</p>
      </>
    ),
  },
  {
    // step 7
    ...StepDefaults,
    content: (
      <>
        <p>
          <strong>Connect token for Apps & Books</strong>
        </p>
        <p>
          Visit the VPP page and click “Add Token” to connect a token to sync licenses for apps and books from Apple Business Manager/Apple School Manager.
        </p>
      </>
    ),
    placement: "right",
    target: ".vpp-link",
  },
  // step 8
  PlaceholderStep, // "Done" rendered in NextJS "Devices" page
]

const Styles = {
  options: { primaryColor: "#0066F1" },
}

export default function JoyrideV2() {
  const introParam = useMemo(() => parseQuery().intro, [])
  const [step, setStep] = useState(null)
  const [finished, setFinished] = useState(false)
  const goToDevicesStep = useCallback((step) => {
    visitURL(v2Route("devices", { intro: step }))
  }, [])

  const callback = useCallback(({action, lifecycle, status}) => {
    if (step == null) return
    if (status === "finished") return setFinished(true)

    switch (action) {
      case "next":
        if (lifecycle !== "complete") return

        if (step === LastCatalogStepNumber) {
          goToDevicesStep(LastCatalogStepNumber+1)
        } else {
          setStep(step + 1)
        }
        break
      case "prev":
        if (lifecycle !== "complete") return

        if (step === FirstCatalogStepNumber) {
          goToDevicesStep(FirstCatalogStepNumber-1)
        } else {
          setStep(step - 1)
        }
        break
      case "skip":
      case "close":
        setFinished(true)
        break
    }
  }, [step])

  useEffect(() => {
    if (step == null && introParam != null) {
      const introParamNum = parseInt(introParam, 10)
      setStep(isNaN(introParamNum) ? 6 : introParamNum)
    } else if (step != null && (step > LastCatalogStepNumber || step < FirstCatalogStepNumber)) {
      goToDevicesStep(step)
    }
  }, [introParam, step])

  if (finished || step == null) return null

  return (
    <ReactJoyride
      callback={callback}
      continuous
      showProgress
      showSkipButton
      stepIndex={step-1}
      steps={Steps}
      styles={Styles}
    />
  )
}
