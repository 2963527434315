import React from 'react';
import { adminNotifications as notificationsClient, users as usersClient } from '../../ajax_clients';
import { bindMethods, isEmpty } from '../../shared/functions';
import pluralize from 'pluralize';
import AdminNotificationUserSelector from "./admin_notification_user_selector";
import AdminNotificationReminderSelector from './admin_notification_reminder_selector'
import { Button } from "react-bootstrap"

export default class AdminNotifications extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentUser:   {},
            users:         [],
            notifications: this.formatNotifications(props.data) || {},
            policy:        props.policy || {},
            editType:      null,
        }
        bindMethods(this);
    }

    componentDidMount() {
        if (isEmpty(this.state.notifications)) {
            this.loadNotifications();
        }

        this.loadUsers();
        this.loadCurrentUser();
    }

    loadUsers() {
        usersClient.read('all').then(({ data: users }) => this.setState({ users }))
    }

    loadCurrentUser() {
        usersClient.current().then(({ data: currentUser }) => this.setState({ currentUser }))
    }

    formatNotifications(notificationsArray) {
        return notificationsArray.reduce((obj, notification) => {
            obj[notification.type] = notification
            return obj
        }, {})
    }

    loadNotifications() {
        notificationsClient.read().then(({ data, policy }) => {
            const notifications = this.formatNotifications(data)
            this.setState({ notifications, policy })
        })
    }

    editNotification(notification) {
        this.setState({ editType: notification })
    }

    closeEditNotification() {
        this.setState({ editType: null })
    }

    updateNotificationDays(notification, daysSince = null, daysBefore = null) {
        const newNotification = { ...notification }
        if (daysSince != null) {
            newNotification.daysSince = daysSince
        }
        if (daysBefore != null) {
            newNotification.daysBefore = daysBefore
        }
        this.updateNotification(newNotification)
        notificationsClient.update(newNotification)
            .then(({ data }) => this.updateNotification(data))
            .catch(this.loadNotifications)
    }

    onDaysSinceSelect(notification, value) {
        this.updateNotificationDays(notification, value)
    }

    onDaysBeforeSelect(notification, value) {
        let { daysBefore, intervalOptions } = notification
        intervalOptions = intervalOptions.map(item => item.value)

        if (value == 0) {
            daysBefore = [0]
        } else {
            daysBefore = daysBefore.filter(item => item != 0)
            if (daysBefore.includes(value)) {
                if (intervalOptions.includes(0) || (!intervalOptions.includes(0) && daysBefore.length > 1)) {
                    daysBefore = daysBefore.filter(item => item != value)
                }
            } else {
                daysBefore.push(value)
            }
        }

        if (daysBefore.length == 0) {
            daysBefore = [0]
        }

        this.updateNotificationDays(notification, null, daysBefore);
    }

    renderReminderSelector(notificationType, onSelect) {
        const notification = this.state.notifications[notificationType]
        const selected = notification.daysSince != undefined ? notification.daysSince : notification.daysBefore

        if (notification) {
            return (
                <AdminNotificationReminderSelector
                    notification={ notification }
                    onSelect={ onSelect }
                    selected={ selected } />
            )
        } else {
            return <Button bsSize="small" disabled>Loading...</Button>
        }
    }

    renderUsers(notificationType) {
        const notification = this.state.notifications[notificationType];
        if (notification) {
            const numberOfUsers = notification.users.length;
            let maxUsers        = 3;
            let listItems;
            if (numberOfUsers === 0) {
                listItems = [<li key="none">none</li>]
            } else {
                listItems = notification.users.slice(0, maxUsers).map(
                    user => <li key={ user.id }>{ user.email } { user.sso ? '(sso)' : null }</li>
                )
            }

            if (numberOfUsers > maxUsers) {
                const numberOfExtraUsers = numberOfUsers - maxUsers;
                listItems.push(<li key="extra-users">
                    + { numberOfExtraUsers } { pluralize('other', numberOfExtraUsers) }
                </li>)
            }

            const editHandler = (e) => {
                e.preventDefault();
                return this.editNotification(notification.type);
            }

            listItems.push(<li key="edit"><a href="#edit-notification" onClick={ editHandler }>edit</a></li>)
            return (
                <ul>
                    { listItems }
                </ul>
            )
        } else {
            return "Loading..."
        }
    }

    updateNotification(userNotification) {
        this.setState({
            notifications: { ...this.state.notifications, [userNotification.type]: userNotification }
        })
    }

    renderUserSelector() {
        let notification = this.state.notifications[this.state.editType];
        if (notification) {
            return <AdminNotificationUserSelector
                policy={ this.state.policy }
                users={ this.state.users }
                currentUser={ this.state.currentUser }
                currentNotification={ notification }
                notifications={ this.state.notifications }
                onClose={ this.closeEditNotification }
                onUpdate={ this.updateNotification }
                onError={ this.loadNotifications }
            />
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="alert alert-info">Optionally receive email notifications when certain events occur.</div>
                <div className="headers">
                    <div className="row">
                        <div className="event col-xs-9">Event</div>
                        <div className="notify col-xs-3">Notify</div>
                    </div>
                </div>

                <div className="row notification-row">
                    <div className="event col-xs-9">
                        <span>When a VPP token is set to expire</span>
                        { this.renderReminderSelector('vppTokenExpiration', this.onDaysBeforeSelect) }
                    </div>
                    <div className="notify col-xs-3">
                        { this.renderUsers('vppTokenExpiration') }
                    </div>
                </div>

                <div className="row notification-row">
                    <div className="event col-xs-9">
                        <span>When a DEP token is set to expire</span>
                        { this.renderReminderSelector('depTokenExpiration', this.onDaysBeforeSelect) }
                    </div>
                    <div className="notify col-xs-3">
                        { this.renderUsers('depTokenExpiration') }
                    </div>
                </div>

                <div className="row notification-row">
                    <div className="event col-xs-9">
                        <span>When a push certificate is set to expire</span>
                        { this.renderReminderSelector('pushCertExpiration', this.onDaysBeforeSelect) }
                    </div>
                    <div className="notify col-xs-3">
                        { this.renderUsers('pushCertExpiration') }
                    </div>
                </div>

                <div className="row notification-row">
                    <div className="event col-xs-9">When a device is manually unenrolled</div>
                    <div className="notify col-xs-3">
                        { this.renderUsers('deviceUnenrolled') }
                    </div>
                </div>
                <div className="row notification-row">
                    <div className="event col-xs-9">When a device enrolls</div>
                    <div className="notify col-xs-3">
                        { this.renderUsers('deviceEnrolled') }
                    </div>
                </div>
                <div className="row notification-row">
                    <div className="event col-xs-9">
                        <span>When a device hasn't been seen for</span>
                        { this.renderReminderSelector('deviceLastSeen', this.onDaysSinceSelect) }
                    </div>
                    <div className="notify col-xs-3">
                        { this.renderUsers('deviceLastSeen') }
                    </div>
                </div>
                <div className="row notification-row">
                    <div className="event col-xs-9">
                        <span>When the SimpleMDM iOS app stops reporting location for</span>
                        { this.renderReminderSelector('deviceLocation', this.onDaysSinceSelect) }
                    </div>
                    <div className="notify col-xs-3">
                        { this.renderUsers('deviceLocation') }
                    </div>
                </div>
                { this.renderUserSelector() }
            </React.Fragment>
        )

    }
}
